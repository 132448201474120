import React, { useState } from 'react';
import Header from './Header';
import axios from 'axios';
import Background from './Background';
import { useEffect } from 'react';
import Login from './Login';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'; 
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import NewHeader from './NewHeader';

const SignUp = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [text, setText] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [notice, setNotice] = useState('');
    const [otp, setOtp] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };


    const showText = (message) => {
        setText(message);
        
        // Set a timeout to clear the notice after 3 seconds
        setTimeout(() => {
          setText('');
        }, 10000); // 3000 milliseconds = 3 seconds
      };






    const showNotice = (message) => {
        setNotice(message);
        
        // Set a timeout to clear the notice after 3 seconds
        setTimeout(() => {
          setNotice('');

        }, 3000); // 3000 milliseconds = 3 seconds
      };


     
     
   

      const showNoticeUp = (message) => {
        setMessage(message);
        
        // Set a timeout to clear the notice after 3 seconds
        setTimeout(() => {
          setMessage('');
          navigate('/Login');

        }, 3000); // 3000 milliseconds = 3 seconds
      };





    const handleSubmit = async (e) => {
        e.preventDefault();


        const passwordRegex = /^(?=.*[A-Z]).{8,}$/;

        if (!passwordRegex.test(password)) {
            showNotice('Oops!, Password must be at least 8 characters long and contain at least one capital letter.');
            return;
        }


        try {
           
              

                    const response = await axios.post('https://mybackend-new.onrender.com/send-otp', {
                        username,
                        password,
                        email,
                        gender,
                        phoneNumber
                    });

                    if (response.data.success) {
                        setIsOtpSent(true);
                        setMessage('OTP sent to your email. Please enter it above.');
                    } else {
                        setMessage('Failed to send OTP. Please try again.');
                    }
                
            
        } catch (error) {
            console.error('Sign up failed:', error);
            setMessage('Sign up failed. Please try again.');
        }
    };




    const handleOtpSubmit = async (e) => {
        e.preventDefault();


        function removeSpaces(str) {
            return str.replace(/\s+/g, '');
          }  
         
          const phoneWithoutSpaces = removeSpaces(phoneNumber);
    
          console.log(phoneWithoutSpaces);





        try {
            const response = await axios.post('https://mybackend-new.onrender.com/verify-otp', {
                email,
                otp,
                username,
                password,
                gender,
                phoneWithoutSpaces
            });

            if (response.data.success) {
                showNoticeUp('User signed up successfully. Redirecting you to Login page...');
               

            } else {
                showNoticeUp('Invalid OTP. Please try again.');
            }
        } catch (error) {
            console.error('OTP verification failed:', error);
            setMessage('The email already exist. Please use another email.');
        }
    };

    return (
        <div className="relative min-h-screen">
           
            <div className="relative z-10">
               
                <NewHeader/>
                <div className="flex  justify-center bg-opacity-0 bg-gray-100 pt-10">
                    <div className="max-w-md w-full p-6 bg-daisy-200 rounded-3xl shadow-lg  m-3 ">
                        <h2 className="text-3xl font-bold mb-1 pt-0 text-center">Sign Up</h2>
                        <h5 className='text-center text-xs mb-3'>Welcome! Sign up to enjoy our services.</h5>
                        {!isOtpSent ? (
                            <form onSubmit={handleSubmit}>
                                <div className="mb-2 ">
                                   
                                    <input
                                        type="text"
                                        id="username"
                                        name="username"
                                        value={username}
                                        placeholder='Enter Username'
                                        onChange={(e) => setUsername(e.target.value)}
                                        className="w-full px-2 py-2 border border-gray-300 rounded-3xl focus:outline-none focus:border-blue-500"
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                   

                                    <div className="mb-2 relative">
                                    <input
        type={showPassword ? 'text' : 'password'}
        id="password"
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder='Enter Password'
        className="w-full px-3 py-2 border border-gray-300 rounded-3xl focus:outline-none focus:border-blue-500 pr-10" // Added pr-10 to make room for the icon inside the input
        required
      />
     
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
        >
          {showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
        </button>
        </div>
      
                                </div>
                               
                                {notice && <p className="text-red-500 text-center mb-2 text-sm mt-4">{notice}</p>}
                                <div className="mb-2">
                                    
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={email}
                                        placeholder='Enter a valid Email'
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-3xl focus:outline-none focus:border-blue-500"
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                   
                                    <input
                                        type="text"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        placeholder="Enter Phone Number code e.g. +2659928893346"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-3xl focus:outline-none focus:border-blue-500"
                                        required
                                    />
                                </div>
                               
                                <div className="mb-2">
                                
                                    <select
                                        id="gender"
                                        name="gender"
                                        value={gender}
                                        onChange={(e) => setGender(e.target.value)}
                                        className="w-full px-3 py-1 border border-gray-300 rounded-3xl focus:outline-none focus:border-blue-500"
                                        required
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                                <button
                                    type="submit"
                                    className="w-full bg-gray-600 text-white py-2 px-4 rounded-xl hover:bg-gray-500 focus:outline-none focus:bg-blue-600"
                                >
                                    Sign Up
                                </button>
                            </form>
                        ) : (
                            <form onSubmit={handleOtpSubmit}>
                                <div className="mb-4">
                                    <label htmlFor="otp" className="block text-sm font-medium text-gray-700">
                                        Enter OTP
                                    </label>
                                    <input
                                        type="text"
                                        id="otp"
                                        name="otp"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                        required
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="w-full bg-gray-700 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                >
                                    Verify OTP
                                </button>
                            </form>
                        )}
                        {message && <p className="text-green-500 text-sm text-center mt-4">{message}</p>}
                        {text && <p className="text-red-500 text-center mt-4">{text}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
