import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NewHeader from './NewHeader';
import { useSearchParams } from 'react-router-dom';
import { MdCancel } from "react-icons/md";
import { ImProfile } from "react-icons/im";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from 'react-router-dom';
import { FaSearch } from "react-icons/fa";

function Market() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [showPersonModal, setShowPersonModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get('search') || ''); // Initialize searchTerm from URL

  useEffect(() => {
    fetchProducts();
  }, [searchTerm]); // Refetch products whenever searchTerm changes

  const fetchProducts = async () => {
    try {
      const res = await axios.get(`https://mybackend-new.onrender.com/products?search=${searchTerm}`);
      const shuffledProducts = shuffleArray(res.data); // Shuffle products randomly
      setProducts(shuffledProducts);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching products:', err);
      setLoading(false);
    }
  };

  // Fisher-Yates shuffle algorithm to randomize the order of products
  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    setSearchParams({ search: newSearchTerm }); // Update the URL with the new search term
  };

  const toggleImage = (image) => {
    setSelectedImage(selectedImage === image ? null : image);
  };

  // Filter products based on search term
  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.description.toLowerCase().includes(searchTerm.toLowerCase())
  );


  const togglePersonModal = (product) => {
    setCurrentProduct(product);
    setShowPersonModal(!showPersonModal);
  };

  return (
    <div>
      <NewHeader />
      <div className="relative min-h-screen flex">


      {showPersonModal && currentProduct && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className=" border bg-white border-gray-300  w-full max-w-lg h-full overflow-auto">
      
    <button className='mt-2'
          onClick={togglePersonModal}>
        <IoIosArrowBack size={24} />
      </button>
      {/* Full Picture at the Top */}
      <div className='w-full'>
        {currentProduct.picture && (
          <img
            src={`${currentProduct.picture}`}
            alt={currentProduct.name}
            onClick={() => toggleImage(`${currentProduct.picture}`)}
            className="w-full h-80 object-cover rounded-lg border-2 border-gray"
          />
        )}
      </div>

      {/* Other Content Below the Picture */}
      <div className="p-4">
      <p className='text-lg font-bold'> {currentProduct.name}</p>
        <p className='text-2xs  mb-2 text-gray-700 text-sm mb-2  max-h-24 overflow-hidden'> {currentProduct.description}</p>
        <p className='text-2xs font-bold'>Price: MK{currentProduct.price}</p>
        <p className='text-2xs'>Status: {currentProduct.status}</p>


        <div className="flex items-center justify-center mt-4">
          <ImProfile size={20} className="mr-1" />
          <h2 className="text-md font-bold text-center">Our Business Profile</h2>
        </div>

        <hr className="border-t-2 border-gray-600 mb-4 mt-2" />
        
        <p className='mb-2 text-gray-700 text-center text-sm mb-2  mt-2 max-h-24 '> {currentProduct.userDescription}</p>
        <p className='header text-2xs'>Seller: {currentProduct.owner_username}</p>
        <p className='header text-2xs'>Location: {currentProduct.location}</p>

        <div className="flex space-x-4 mt-2">
          <button
            onClick={togglePersonModal}
            className="p-2 bg-red-800 text-white rounded-xl flex items-center"
          >
            <MdCancel className="mr-1" />
            Close
          </button>

        <Link to="/Login">
          <button        
                        className="text-gray-800 text-md font-semibold mr p-0 border-none bg-gray-300 rounded-lg cursor-pointer"
                      >
                       <h6 className='p-2' >Shop Now</h6>
                     
                      </button>

           </Link>  
                   
        </div>
      </div>
    </div>
  </div>
)}
        <div className="flex-1 p-1 flex">
          {loading ? (
            <div className='text-black mt-24 items-center justify-center'>
              loading the products...
            </div>
          ) : (
            <div className="flex-1 p-0 rounded-6xl pt-14">
              <div className="bg-white">
                <div className="mb-2 relative">
                <FaSearch className="absolute mr-auto right-3 top-1/2 transform -translate-y-1/2 text-gray-500" size={20} />
                        
                  <input
                    type="text"
                    placeholder="Search by name, description, or owner"
                    value={searchTerm}
                    onChange={handleSearchChange} // Capture input changes
                    className="p-2 border border-gray-300 w-full rounded-md"
                  />
                          
                </div>
                <div className="grid grid-cols-2 md:grid-cols-5 gap-1">
                  {filteredProducts.map(product => (
                    <div key={product.id} className="border rounded shadow rounded-md">
                      <img
                        src={product.picture}
                        alt={product.name}
                        onClick={() => togglePersonModal(product)}
                        className="w-full h-44 object-cover mb-2 rounded-sm"
                      />
                      <div className="pl-1 pr-1">
                        <h2 className="text-sm font-bold">{product.name}</h2>
                        <p className="text-sm">MK{product.price}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {selectedImage && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
            <div className="relative">
              <img
                src={selectedImage}
                alt="Product"
                className="max-w-full max-h-full rounded-3xl"
                onClick={() => setSelectedImage(null)}
              />
              <button
                onClick={() => setSelectedImage(null)}
                className="absolute top-0 right-0 p-2 text-white bg-black rounded-full"
              >
                &times;
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Market;
