import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import InsideHeader from './InsideHeader';
import { UserContext } from './UserContext';
import { MdShoppingCart } from "react-icons/md";
import { IoCartOutline } from "react-icons/io5";
import { RiWhatsappFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import AnotherHeader from './AnotherHeader';
import { BsTrash } from 'react-icons/bs';
import { BsWhatsapp } from "react-icons/bs";
import {IoMdCall } from "react-icons/io";
import { ImProfile } from "react-icons/im";
import { CiShoppingCart } from "react-icons/ci";
import NewHeader from './NewHeader';

const MyOrders = () => {


  return (
    <div className="flex flex-col min-h-screen items-center justify-center">
     <NewHeader/>
                
        <div className=" p-2">
            <div className='' >

            <h1 className='flex justify-center bg-center items-center'><CiShoppingCart size={80} /></h1>
            <p className='font-semibold text-lg text-center' >Add items to your cart</p>

            </div>
               
             <p className='text-center' >Buy products from the marketplace and use your cart to communicate with the seller and collect your requested product.</p>   

              </div>
             
      </div>
 
  );
};

export default MyOrders;
