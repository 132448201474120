import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // Import your UserContext
import { BiLogOutCircle } from "react-icons/bi";
import { GiTakeMyMoney } from "react-icons/gi";
import { HiInformationCircle } from "react-icons/hi";
import { Link } from 'react-router-dom';
import Login from './Login';
import { FaLeaf } from 'react-icons/fa';
import { useEffect, useState} from 'react';
import { FaCodePullRequest } from "react-icons/fa6";
import axios from 'axios';

const AnotherHeader = ({ isOpen, toggleSidebar }) => {
  const { setUser } = useContext(UserContext);
  const { user } = useContext(UserContext);
  const [cartNotificationCount, setCartNotificationCount] = useState(0);
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/Login'); // Redirect to the login page
    window.location.reload();
    localStorage.removeItem('token'); // Remove token from localStorage
    setUser(null); // Clear the user state

  };


 

  useEffect(() => {

 

    if (user && user.id) {
      const fetchCartNotificationCount = async () => {
        try {
          const response = await axios.get('https://mybackend-new.onrender.com/cart-notification-count', {
            params: { owner_id: user.id } // Use user.id from the context
          });
          setCartNotificationCount(response.data.count);
        } catch (error) {
          console.error('Error fetching cart notification count:', error);
        }
      };

      fetchCartNotificationCount();
    }
  }, [user]);





  return (
    <header className="fixed top-0 left-0 w-full bg-white text-gray-700 z-40 flex items-center justify-between p-3 shadow-xl">
      <button onClick={handleLogout}>
        <BiLogOutCircle size={20} />
      </button>
      <div className='flex flex-grow items-center justify-center'>
        <button className="flex mr-2 items-center font-bold text-2xs md:ml-4 bg-transparent border-none">
          <FaLeaf size={18} className="ml-2 mr-1 font-bold" />
          <span className='page text-xl text-gray-800'>Waiiona</span>
        </button>
      </div>
      <Link to="/Notification">
          <button  className="relative flex flex-col mr-1 items-center text-2xs md:ml-4 bg-transparent border-none">
            <FaCodePullRequest size={17} className="ml-2 mr-2  hover:text-gray-400" />
            {cartNotificationCount > 0 && (
              <span className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full px-1">
                {cartNotificationCount}
              </span>
            )}
            
          </button>
        </Link>
      <Link to="/about">
        <button className="flex m items-center  text-2xs md:ml-4 bg-transparent border-none">
          <HiInformationCircle size={23} className="ml-1 " />
        </button>
      </Link>
    </header>
  );
};

export default AnotherHeader;
